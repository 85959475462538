import React from "react";

import { BrowserRouter,Routes,Route } from "react-router-dom";

import Login from "./Login";
import App from "./App";
import Confirmedlist from "./Confirmedlist";
import Pendinglist from "./Pendinglist";


function AppRouter() {
  return(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/App" element={<App />} />
        <Route path="/Confirmedlist" element={<Confirmedlist />} />
        <Route path="/Pendinglist" element={<Pendinglist />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter