import React,{useState} from 'react';
import { useNavigate,useLocation } from "react-router-dom";

import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import GlobalVars from './GlobalVars';
import axios from 'axios';

import './Confirmedlist.css';

function Confirmedlist() {
    // Variables
    const {state} = useLocation();
    const { guests, count_assist } = state; // Read values passed on state

    // Constante de navegacion
    let navigate = useNavigate(); 

    // Manejo de estados
    let arrTextState = {};
    let arrActionBlock = {}

    guests.forEach(function (guest){
        arrTextState[guest.inv_code]=guest.wp_comment!=null?guest.wp_comment:"";
        arrActionBlock[guest.inv_code]=guest.wp_comment!=null?true:false;
    });

    const [textState, setTextState] = useState(arrTextState);
    const [actionBlock, setActionBlock] = useState(arrActionBlock);

    const handleClickBack = event => {
        event.preventDefault();
        navigate(-1);
    };

    const handleSaveNote = event => {
        event.preventDefault();
        let arrID = event.target.id.split('_')
        let elemId =arrID[0]
        let comment = document.getElementById(elemId+"_nt").value
        console.log(elemId)
        console.log(comment)
        
        const url = GlobalVars.api_url + "/api/guests/comment";
        const json_creads = JSON.stringify({ guest: elemId, comment: comment });

        axios.post(url, json_creads, {
            headers: {
            'Content-Type': 'application/json',
            'x-api-key': GlobalVars.api_key
            }
        })
        .then(res => {
            let resp = res.data;
            console.log(resp);
            //Bloquear campo
            let arrActionBlock2 = Object.assign({}, actionBlock);
            arrActionBlock2[event.target.id.replace("_btn","")]=true;
            setActionBlock(arrActionBlock2);
            console.log(actionBlock);
        })
        .catch(err => {
            // Handle error
            let resp = err.response.data;
            console.log(resp);
            // setErrorMessage(resp["state"]["message"]);
            // Desbloquear campo por error
        });
    };

    const handleWriteChange = event =>{
        let arrTextState2 = Object.assign({}, textState);
        arrTextState2[event.target.id.replace("_nt","")]=event.target.value;
        setTextState(arrTextState2);
    };

    let guest_list = [];
    guests.forEach(function (guest){
        guest_list.push(
        <tr key={guest.inv_code+"_key"}>
            <td className='name'>{guest.name}</td>
            <td className='contactconfirmed2'>{guest.cel}</td>
            <td className='ind-cod'>{guest.inv_code}</td>
            <td className='nummesa'>{guest.table_code}</td>
        </tr>
        )
    });
    return (
        <div className="Confirmedlist">
            <Card body className='allconfirmed'>Lista de Asistentes: {count_assist}</Card>
            <Card className='conftab'>
                <Table className='tablei' striped bordered hover responsive="xl">
                    <thead>
                        <tr>
                            <th className='name'>Nombre invitado</th>
                            <th className='contactconfirmed2'>Contacto</th>
                            <th className='ind-cod'>Codigo personal</th>
                            <th className='nummesa'>Mesa</th>
                        </tr>
                    </thead>
                    <tbody>
                        {guest_list}
                    </tbody>
                </Table>
                <br />
                <Button className='gobakfrmconfirmd' variant="outline-secondary" onClick={handleClickBack}>Volver</Button>{' '}
                <br />
            </Card>
        </div>
    );
}

export default Confirmedlist;
