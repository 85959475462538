import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from 'axios';

import './Login.css';
import GlobalVars from './GlobalVars';



function Login() {
  // Variables
  const [user, setUser] = useState('')
  const [pass, setPass] = useState('')
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [btnDisabled, setDisabled] = useState(false)
  const [invalidForm, setInvalidForm] = useState(false)
  // Constante de navegacion
  let navigate = useNavigate();

  // Funcion de clic
  const handleSubmit = (event) => {
    event.preventDefault()
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    else {
      // Ajax para llamar endpoint de autenticación
      const url = GlobalVars.api_url + "/api/login";
      const json_creads = JSON.stringify({ user: user, pass: pass });
      setDisabled(true);
      axios.post(url, json_creads, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': GlobalVars.api_key
        }
      })
        .then(res => {
          let resp = res.data;
          console.log(resp)
          console.log(res)
          localStorage.setItem("wed_guest_token", resp["state"]["access_token"]);
          localStorage.setItem("wed_guest_ref_token", resp["state"]["refresh_token"]);
          let path = '/App'; // <--- Pones a que pagina vas
          navigate(path);
        }).catch(err => {
          // Handle error
          let resp = err.response.data;
          setErrorMessage(resp["state"]["message"]);
          setValidated(false);
          setInvalidForm(true)
          setDisabled(false);
        });
    }
    setValidated(true);
  };

  const handlePassChange = event => {
    setPass(event.target.value);
    //console.log('password is:', event.target.value);
  };

  const handleUserChange = event => {
    setUser(event.target.value);
    //console.log('user is:', event.target.value);
  };

  return (
    <div className='Login'>
      <Card body className='welcomelogin'>Bienvenido!</Card>
      <Card body className='enter'>
        Ingresa tu usuario y contraseña
        <br />para accesar a la lista de invitados
      </Card>
      <Form className='loginn' noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicText">
          <Form.Label>Usuario</Form.Label>
          <Form.Control className='textuser' type="text" placeholder="Ingresa usuario" onChange={handleUserChange} value={user} required isInvalid={invalidForm}/>
          <Form.Control.Feedback type="invalid">
            Ingresa un usuario valido.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 labelpassword" controlId="formBasicPassword">
          <Form.Label>Constraseña</Form.Label>
          <Form.Control className='textuser' type="password" placeholder="Contraseña" onChange={handlePassChange} value={pass} required isInvalid={invalidForm}/>
          <Form.Control.Feedback type="invalid">
            Ingresa una contraseña.
          </Form.Control.Feedback>
        </Form.Group>
        <Button variant="outline-secondary" type="submit" disabled={btnDisabled}>Ingresar</Button>
      </Form>
      <br />
      {errorMessage && <div className="error"> {errorMessage} </div>}
    </div>
  );
}

export default Login;

