import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import GlobalVars from './GlobalVars';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './App.css';

function App() {
  // Variables
  const [code, setCode] = useState('');
  const inputReference = useRef(null);

  const [btnDisabled,setDisabled] = useState(false);

  const [modalMessageUps,setMessageUps] = useState({"code":"0000"});
  const [showUps, setShowUps] = useState(false);
  const handleCloseUps = () => setShowUps(false);
  const handleShowUps = () => setShowUps(true);
  const handleModalMessageUps = (msg) => setMessageUps(msg);

  const [modalMessageEmpty,setMessageEmpty] = useState({"code":"0000"});
  const [showEmpty, setShowEmpty] = useState(false);
  const handleCloseEmpty = () => setShowEmpty(false);
  const handleShowEmpty = () => setShowEmpty(true);
  const handleModalMessageEmpty = (msg) => setMessageEmpty(msg);

  const [modalMessageOk,setMessageOk] = useState({"name":"NA","table":"0000"});
  const [showOk, setShowOk] = useState(false);
  const handleCloseOk = () => setShowOk(false);
  const handleShowOk = () => setShowOk(true);
  const handleModalMessageOk = (msg) => setMessageOk(msg);

  useEffect(() => {
      inputReference.current.focus();
  }, []);

  // Constante de navegacion
  let navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('wed_guest_token');
    if (token === "" || token === null) {
      let path = '/'; // <--- Pones a que pagina vas
      navigate(path);
    }

  });

  // Funcion de clic
  const handleClick = event => {
    event.preventDefault();
    console.log("code: ", code)

    if(code !== ""){
      setDisabled(true);
      //const url = GlobalVars.api_url + "/api/guests/" + code
      const url = GlobalVars.api_url + "/api/invite/assited"
      let req_data = {};
      req_data[code]=true;
      const json_data = JSON.stringify(req_data);
      axios.post(url, json_data, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': GlobalVars.api_key,
          'x-admin-req': "ADMIN"
        }
      })
        .then(res => {
          const persons = res.data;
          console.log(persons)
          //let path = '/App/List'; // <--- Pones a que pagina vas
          //navigate(path, persons);
          setDisabled(false);
          let persona = persons.state.guest;
          if (typeof persona !== "undefined"){
            console.log(persona)
            handleModalMessageOk({"name":persona.name,"table":persona.table_code});
            handleShowOk();
          }
          else{
            console.log("No hay nadie!!")
            handleModalMessageUps({"code":code});
            handleShowUps();
          }
        })
        .catch(function (error){
          setDisabled(false);
          console.log(error.toJSON());
          handleModalMessageUps({"code":code});
          handleShowUps();
        });
    }
    else{
      handleShowEmpty();
    }
  };

  const handleCodeChange = event => {
    setCode(event.target.value);
    //console.log('code is:', event.target.value);
  };

  //Confirmed list button
  const handleConflistClick = event => {
    event.preventDefault();

    const url = GlobalVars.api_url + "/api/guests/confirmed"
    axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': GlobalVars.api_key
      }
    })
      .then(res => {
        const persons = res.data;
        console.log(persons)
        let path = '/Confirmedlist'; // <--- Pones a que pagina vas
        navigate(path, persons);
      });
  }

  //Confirmed list button
  const handlePendinglistClick = event => {
    event.preventDefault();
    console.log("code: ", code)

    const url = GlobalVars.api_url + "/api/guests/toconfirm"
    axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': GlobalVars.api_key
      }
    })
      .then(res => {
        const persons = res.data;
        console.log(persons)
        let path = '/Pendinglist'; // <--- Pones a que pagina vas
        navigate(path, persons);
      });
  }

  //For validations
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <div className="App">
      <Card body className='instructionone'>Por favor, ingresa el codigo de invitacion o el codigo personal.</Card>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <FloatingLabel controlId="floatingInput" label="Codigo de invitacion" className="mb-3 inputcodinv" >
          <Form.Control type="text" placeholder="MYO28EN23-F01" onChange={handleCodeChange} value={code} aria-describedby="inputGroupPrepend" autoComplete="off" required ref={inputReference} />
          <Form.Control.Feedback type="invalid" className='textareacode'>
            Ingresa codigo.
          </Form.Control.Feedback>
        </FloatingLabel>
        <Card className='sendbuttoncard'>
          <Button disabled={btnDisabled} className="sendbutton" variant="outline-secondary" type="submit" onClick={handleClick}>Enviar</Button>
        </Card>
      </Form>

      <br />
      <Container className="buttons-lists">
        <Row>
          <Col sm={6}>
            <Card className='confirmd-list'>
              <Button className='allbutton' variant="outline-primary" type="submit" onClick={handleConflistClick}>Ver lista de Check-In a Sal&oacute;n</Button>{' '}
            </Card>
          </Col>
          <Col sm={6}>
            <Card className='lista-pendientes'>
              <Button className='pendientesbutton' variant="outline-primary" type="submit" onClick={handlePendinglistClick}>Ver lista de Confirmados</Button>{' '}
            </Card>
          </Col>
        </Row>
      </Container>
      <div className="modalgoback">
        <Modal show={showEmpty} onHide={handleCloseEmpty} backdrop="static" keyboard={false}>
          <Modal.Body className="modaltextconfirmed">
            <h4 className="modalups">Ups!</h4>
            <h5 className="msjmodalups">
              Debes ingresar un valor!
            </h5>
            <Button variant="outline-secondary" onClick={handleCloseEmpty} className="goback-modal">Volver</Button>
          </Modal.Body>
        </Modal>

        <Modal show={showUps} onHide={handleCloseUps} backdrop="static" keyboard={false}>
          <Modal.Body className="modaltextconfirmed">
            <h4 className="modalups">Ups!</h4>
            <h5 className="msjmodalups">
              <br />El codigo 
              <br /><span className='nocoderegis'>{modalMessageUps.code}</span>
              <br />no confirmo o no se encuentra registrado.
              <br />
              <br /> Lo sentimos!
            </h5>
            <Button variant="outline-secondary" onClick={handleCloseUps} className="goback-modal">Volver</Button>
          </Modal.Body>
        </Modal>

        <Modal show={showOk} onHide={handleCloseOk} backdrop="static" keyboard={false}>
          <Modal.Body className="modaltextconfirmed">
            <h4 className="modalh4">Gracias!</h4>
            <h5 className="msjmodal">
              <br />
              <span className='invfullname'>{modalMessageOk.name}</span>
              <br />se ha registrado correctamente!
              <br/>Su mesa es la <span className='tablenumber'>{modalMessageOk.table}</span>
            </h5>
            <Button variant="outline-secondary" onClick={handleCloseOk} className="goback-modal">Volver</Button>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default App;
