import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Card from 'react-bootstrap/Card';

import AppRouter from './AppRouter';
import './index.css';

import headerguests from './img/headerguests.png';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className='Container-sm'>
      <header className='header'>
        <img src={headerguests} className="headerguests" alt="headerguests" />
        {/*<Card body className='top'>Saray & Nombre
        <br />Lista de invitados</Card>*/}
      </header>
      <div className='approuter'>
        <AppRouter />
      </div>
      <footer>
        <Card body className='derres'>Coded by MoszykBloo MLCC | Derechos Reservados 2023</Card>
      </footer>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
