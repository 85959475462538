import React from 'react';
import { useNavigate,useLocation } from "react-router-dom";

import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import './Pendinglist.css';

function Pendinglist() {
    // Variables
    const {state} = useLocation();
    const { guests, count_noassist } = state; // Read values passed on state

    // Constante de navegacion
    let navigate = useNavigate(); 

    const handleClickBack = event => {
        event.preventDefault();
        navigate(-1);
    };

    let guest_list = [];
    guests.forEach(function (guest){
        guest_list.push(
        <tr>
            <td className='name'>{guest.name}</td>
            <td className='contactconfirmed2'>{guest.cel}</td>
            <td className='ind-cod'>{guest.inv_code}</td>
            <td className='nummesa'>{guest.table_code}</td>
        </tr>
        )
    });

    return (
        <div className="Pendinglist">
            <Card className='sticky-top pending-list'>Lista de Confirmados: {count_noassist}</Card>
            <Card className='conftab2'>
                <Table className='tablei2' striped bordered hover responsive="xl">
                    <thead>
                        <tr>
                            <th>Nombre invitado</th>
                            <th className='contactconfirmed'>Contacto</th>
                            <th className='codper'>Codigo personal</th>
                            <th className='numesa'>Mesa asignada</th>
                        </tr>
                    </thead>
                    <tbody>
                        {guest_list}
                    </tbody>
                </Table>
                <br />
                <Button className='gobakfrmconfirmd' variant="outline-secondary" onClick={handleClickBack}>Volver</Button>{' '}
                <br />
            </Card>
        </div>
    );
}

export default Pendinglist;
